export enum User_Role_Enum {
    // SuperAdmin = 'SUPER-ADMIN',
    SpaceOwner = 'SPACE-OWNER',
    Admin = 'ADMIN',
    Manager = 'MANAGER',
    Editor = 'EDITOR',
    Author = 'AUTHOR',
    Contractor = 'CONTRACTOR'
}

export enum User_Status_Enum {
    Unverified = 'UNVERIFIED',
    Active = 'ACTIVE',
    Disabled = 'DISABLED',
    Deleted = 'DELETED'
}

export const Space_Invitation_Enum = {
    Accepted: null,
    Pending: 'PENDING',
    Declined: 'DECLINED'
} as const

export enum Space_User_Status_Enum {
    Active = 'ACTIVE',
    Deactive = 'DEACTIVE',
    Pending = 'PENDING',
    Declined = 'DECLINED'
}

export const Feed_Type: { [key:string]: string } = {
    csv: 'CSV',
    googlesheet: 'Google Sheet',
    supabase : 'Supabase'
    // xml: 'XML',
} as const
